import { useAtom } from "jotai";

import { Reactions, type ReactionTypes } from "@/src/components/reactions/reaction";
import { reactionCountAtom } from "@/src/components/reactions/reaction_atom";
import { sopotanGaudgeMax } from "@/src/consts";
import { css, cx } from "@/styled-system/css";

type Props = {
  postedBy: "self" | "other";
  reactionType: ReactionTypes;
  isFirst?: boolean;
};

const popRange = {
  pc: [-8, 93],
  sp: [-2, 86],
};

// ばらつき具合
const pointVariability = 3;
// 表示位置調整用
const offset = {
  pc: 1,
  sp: -1,
};

const reactionPopPoint = (reactionCount: number, displayType: "pc" | "sp") => {
  const rawPopPoint = ((reactionCount % sopotanGaudgeMax) / sopotanGaudgeMax) * 100;
  const [min, max] = popRange[displayType];
  const randomPoint = (Math.random() * 2 - 1) * pointVariability;
  const calculatedPopPoint = min + ((max - min) / 100) * (rawPopPoint + randomPoint + offset[displayType]);
  return Math.max(min, Math.min(max, calculatedPopPoint));
};

export const FuwaFuwaSopotan = (props: Props) => {
  if (props.isFirst) {
    return <></>;
  }

  const reactionCount = useAtom(reactionCountAtom)[0];

  const baseCss = css({
    position: "absolute",
    bottom: 2,
    zIndex: 1,
    transformOrigin: "center",
    animation: "fuwafuwa1 1.2s both ease-out",
    pointerEvents: "none",
    opacity: props.postedBy === "self" ? 1 : 0.9,
    _before: {
      content: '""',
      position: "absolute",
      left: 7,
      top: 2,
      width: 3,
      height: 3,
      border: "2px solid #FDC45C",
      display: "block",
      borderRadius: "full",
    },
    _after: {
      content: '""',
      position: "absolute",
      left: 0,
      top: 7,
      width: 4,
      height: 4,
      border: "2px solid #FDC45C",
      display: "block",
      borderRadius: "full",
    },
  });

  return (
    <>
      {/* pc 用 */}
      <div
        className={cx(baseCss, css({ display: "none", lg: { display: "block" } }))}
        style={{
          left: `${reactionPopPoint(reactionCount, "pc")}%`,
        }}
      >
        {/* figmaからエクスポートしてきた時点でdrop-shadowがついてそう */}
        <div className={css({ animation: "fuwafuwa2 0.6s both infinite ease-in-out" })}>
          <div className={css({ animation: "fuwafuwa3 0.6s both infinite linear" })}>
            <img
              src={Reactions[props.reactionType].image}
              width={props.postedBy === "self" ? "75px" : "40px"}
              alt="ウインクしているそぽたん"
            />
          </div>
        </div>
      </div>
      {/* sp 用 */}
      <div
        className={cx(baseCss, css({ lg: { display: "none" } }))}
        style={{
          left: `${reactionPopPoint(reactionCount, "sp")}%`,
        }}
      >
        {/* figmaからエクスポートしてきた時点でdrop-shadowがついてそう */}
        <div className={css({ animation: "fuwafuwa2 0.6s both infinite ease-in-out" })}>
          <div className={css({ animation: "fuwafuwa3 0.6s both infinite linear" })}>
            <img
              src={Reactions[props.reactionType].image}
              width={props.postedBy === "self" ? "75px" : "40px"}
              alt="ウインクしているそぽたん"
            />
          </div>
        </div>
      </div>
    </>
  );
};
