import Timetable from "./timetable";

import { css } from "@/styled-system/css";

export const TimetableContainer = () => {
  return (
    <div className={css({ position: "relative", width: "100%", hideBelow: "lg" })}>
      <h2
        className={css({
          padding: 3,
          background: "sohosai.orange",
          borderTopRadius: "xl",
          color: "white",
          fontWeight: "bold",
        })}
      >
        タイムテーブル
      </h2>
      <hr className={css({ marginBlockStart: 1, borderWidth: "1px", color: "sohosai.orange" })} />

      <Timetable />
    </div>
  );
};
