import { css, cva } from "@/styled-system/css";

const info = () => (
  <div
    className={css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginBlock: 2,
      rowGap: 2,
      alignItems: "center",
      lg: { flexDirection: "column", rowGap: 0, flexWrap: "wrap" },
    })}
  >
    <p
      className={css({
        color: "neutral.600",
        fontSize: "sm",
        whiteSpace: "pre-wrap",
        wordBreak: "keep-all",
        display: "none",
        lg: { display: "block" },
      })}
    >
      さくらインターネット株式会社様にご提供いただいた、 ライブ配信基盤
      <a
        href="https://imageflux.sakura.ad.jp/livestreaming/"
        className={css({ textDecoration: "underline", marginInline: 0.5 })}
      >
        ImageFlux
      </a>
      を利用し配信しています
    </p>
    <DontRecord layout="SP" />
    <div className={css({ display: "flex", justifyContent: "space-around", alignItems: "center", gap: 2 })}>
      <a href="https://imageflux.sakura.ad.jp/livestreaming/" target="_blank" rel="noreferrer">
        <span className={css({ fontSize: { lg: "sm", base: "x-small" } })}>Powered by</span>
        <img src="/imageflux.svg" alt="ImageFlux" className={css({ height: { lg: 6, base: 3 } })} />
      </a>
      <a href="https://sakura.ad.jp/" target="_blank" rel="noreferrer" className={css({ alignSelf: "flex-end" })}>
        <img src="/sakura.svg" alt="さくらインターネット" className={css({ height: { lg: 14, base: 8 } })} />
      </a>
    </div>
  </div>
);

export const DontRecord = (props: { layout: "PC" | "SP" }) => {
  const style = cva({
    base: {
      paddingBlock: 2,
      paddingInline: 4,
      color: "sohosai.blue",
      background: "neutral.200",
      width: "fit-content",
      fontSize: "sm",
      lg: {
        fontSize: "md",
      },
    },
    variants: {
      layout: {
        PC: {
          display: "none",
          lg: {
            display: "block",
          },
        },
        SP: {
          display: "block",
          lg: {
            display: "none",
          },
        },
      },
    },
  });
  return (
    <p className={style({ layout: props.layout })}>配信画面の録画{props.layout === "PC" && "・録音"}はお控えください</p>
  );
};

export default info;
