/* eslint-disable @typescript-eslint/naming-convention */
import { css, cx } from "@/styled-system/css";

type CounterProps = {
  count: number;
  textCss?: string;
  rightJustified?: boolean;
  width?: number;
  height?: number;
};

const CounterInner = ({ count, textCss, width, height }: CounterProps) => {
  return (
    <div className={css({ width: `${width!}px`, height: `${height!}px`, overflow: "hidden" })}>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          transition: "transform 0.3s ease;",
        })}
        style={{ transform: `translateY(-${count * height!}px)` }}
      >
        {[...Array(11)].map((_, i) => (
          <div
            key={i}
            className={cx(
              css({ width: `${width!}px`, height: `${height!}px`, display: "flex", justifyContent: "center" }),
              textCss,
            )}
          >
            <p>{i == 10 ? " " : i}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Counter = ({ count, textCss, width = 10, height = 20, rightJustified }: CounterProps) => {
  const buffer = 10;
  const numbers = count
    .toString()
    .split("")
    .map((n) => parseInt(n));
  const counts = [...Array(buffer)].map((_, i) => numbers[i] ?? 10);

  return (
    <div className={css({ width: `${buffer * width}px`, height: `${height}px`, overflow: "hidden" })}>
      <div
        className={css({ display: "flex", transition: "transform 0.2s ease;" })}
        style={rightJustified ? { transform: `translateX(${(10 - numbers.length) * width}px)` } : undefined}
      >
        {[...Array(10)].map((_, i) => (
          <CounterInner key={i} count={counts[i]} textCss={textCss} width={width} height={height} />
        ))}
      </div>
    </div>
  );
};
