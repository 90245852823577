import { useEffect, useState } from "react";

import { useAtom } from "jotai";

import SendIcon from "./assets/send.svg";
import { postReaction as _postReaction } from "./post_reaction";
import { ReactionKeys } from "./reaction";
import { selectReactionAtom, removeReactionAtom, postReactionAtom, reactionInputAtom } from "./reaction_atom";
import { ReactionInputArea } from "./reactionInputArea";
import { ReactionSelector } from "./reactionSelector";

import { css } from "@/styled-system/css";

export const ReactionInput = () => {
  const [reactionInput] = useAtom(reactionInputAtom);

  const [, selectReaction] = useAtom(selectReactionAtom);
  const [, removeReaction] = useAtom(removeReactionAtom);
  const [, postReaction] = useAtom(postReactionAtom);

  const [pressedKey, setPressedKey] = useState<string | null>(null);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      const key = e.code;
      setPressedKey(key);

      if (key === "Backspace") {
        removeReaction();
      }

      if (key === "Enter") {
        postReaction();
      }

      if (key.startsWith("Digit")) {
        const num = parseInt(key.slice(-1));
        if (0 < num && num <= ReactionKeys.length) {
          selectReaction(ReactionKeys[num - 1]);
        }
      }
    };

    const keyUpHandler = () => {
      setPressedKey(null);
    };

    window.addEventListener("keydown", keyDownHandler);
    window.addEventListener("keyup", keyUpHandler);
  }, [removeReaction, postReaction, selectReaction]);

  return (
    <div className={css({ paddingX: 3, paddingY: 2 })}>
      <div className={css({ display: "flex", alignItems: "center", mb: 2, gap: 4, px: 2, lg: { gap: 22, px: 5 } })}>
        <ReactionInputArea reactions={reactionInput} />
        {/* TODO: エラーハンドリング */}
        <button className={css({ cursor: "pointer" })} onClick={postReaction}>
          <img
            alt="送信"
            src={SendIcon}
            className={css({
              height: 8,
              transition: "0.15s",
              filter: "auto",
              brightness: pressedKey === "Enter" ? 0.5 : undefined,
              _active: { brightness: 0.5 },
            })}
          />
        </button>
      </div>
      <ReactionSelector
        onSelect={selectReaction}
        onRemove={removeReaction}
        pressedKey={pressedKey}
        disabled={reactionInput.length >= 10}
      />
    </div>
  );
};
