import { useAtomValue } from "jotai";
import toast from "react-hot-toast";

import { connectionCountAtom } from "./connection_atom";
import { Counter } from "./counter";
import { DontRecord } from "./info";

import shareIcon from "@/src/assets/share.svg";
import viewerIcon from "@/src/assets/viewer.svg";
import xIcon from "@/src/assets/x.svg";
import { xShareText, siteUrl } from "@/src/consts";
import { css } from "@/styled-system/css";

const Summary = () => {
  const viewer = useAtomValue(connectionCountAtom);
  return (
    <div className={containerStyle}>
      <div className={css({ display: "flex", alignItems: "center" })}>
        <img src={viewerIcon} alt="" className={css({ height: 9, width: 9 })} />
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            gap: "3px",
            color: "sohosai.blue",
            fontWeight: "bold",
            paddingTop: "1.5",
          })}
        >
          <p>視聴者数</p>
          <Counter count={viewer} />
        </div>
      </div>
      <DontRecord layout="PC" />
      <div className={css({ display: "flex", columnGap: 3 })}>
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(xShareText)}`}
          target="_blank"
          rel="noreferrer"
          className={iconStyle}
        >
          <img src={xIcon} alt="X" />
        </a>
        <button className={iconStyle} onClick={share}>
          <div className={css({ paddingRight: "2px" })}>
            <img src={shareIcon} alt="共有" />
          </div>
        </button>
      </div>
    </div>
  );
};

const share = async () => {
  const shareData = {
    title: "雙峰祭UNITEDステージ生配信",
    url: siteUrl,
  };

  if (!window.navigator.share) {
    toast.promise(navigator.clipboard.writeText(shareData.url), {
      loading: "",
      success: "サイトのURLをコピーしました",
      error: "サイトのURLのコピーに失敗しました",
    });
  } else {
    await navigator.share(shareData);
  }
};

const containerStyle = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "white",
  lg: {
    marginBlock: 2,
    marginInline: 4,
    background: "none",
  },
  paddingX: 4,
  paddingY: 2,
  width: "full",
});

const iconStyle = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "sohosai.blue",
  borderRadius: "full",
  height: 8,
  width: 8,

  cursor: "pointer",
});

export default Summary;
