import wink from "./assets/sopotan_wink.png";
import { ReactionContent } from "./reactionContent";

import type { ReactionTypes } from "./reaction";

import { css } from "@/styled-system/css";

export const ReactionInputArea = (props: { reactions: Array<ReactionTypes> }) => {
  return (
    <div
      className={css({
        display: "flex",
        flexWrap: "wrap",
        height: "auto",
        px: 2,
        py: 1.5,
        rounded: "md",
        color: "gray.300",
        border: "3px solid",
        flex: 1,
        overflow: "hidden",
      })}
    >
      {/* プレースホルダー */}
      {props.reactions.length === 0 &&
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Array.from({ length: 3 }).map((_) => (
          <img
            src={wink}
            className={css({ h: 9, objectFit: "contain", filter: "contrast(0%) brightness(160%)" })}
            alt="ウインクしているそぽたん"
          />
        ))}
      {props.reactions.map((reaction) => (
        <ReactionContent className={css({ h: 9, objectFit: "contain" })} reaction={reaction} />
      ))}
    </div>
  );
};
