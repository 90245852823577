import { useEffect, useRef } from "react";

import { useAtom } from "jotai";

import { reactionsAtom } from "./reaction_atom";
import { ReactionItem } from "./reactionItem";

import { css } from "@/styled-system/css";

export const ReactionsView = () => {
  const [reactions] = useAtom(reactionsAtom);
  const view = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (view.current) {
      view.current.scrollTop = view.current.scrollHeight;
    }
  }, [reactions]);
  return (
    <div
      ref={view}
      className={css({
        height: "calc(94vh - 400px)",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        backgroundColor: "white",
        padding: 2,
        paddingBlockStart: 3,
        scrollBehavior: "smooth",
        _scrollbar: { width: "10px" },
        _scrollbarThumb: { borderRadius: "9999px", background: "#ed6d1f" },
        _scrollbarTrack: { borderBottomRightRadius: "9999px", background: "#F5F5F5" },
        lg: {
          padding: 5,
        },
      })}
    >
      {reactions.map((reaction, index) => (
        <ReactionItem key={index} reaction={reaction.content} />
      ))}
    </div>
  );
};
