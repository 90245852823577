import crap from "./assets/sopotan_craps.png";
import heart from "./assets/sopotan_heart.png";
import kirakira from "./assets/sopotan_kirakira.png";
import penlight from "./assets/sopotan_penlight.png";
import sunglasses from "./assets/sopotan_sunglasses.png";

export const ReactionKeys = ["heart", "penlight", "kirakira", "sunglasses", "crap"] as const;
export type ReactionTypes = (typeof ReactionKeys)[number];
export type ReactionData = { id: string; content: Array<ReactionTypes>; created_at: string };

export const Reactions: {
  [key in ReactionTypes]: {
    alt: string;
    image: string;
  };
} = {
  heart: {
    alt: "ハートそぽたん",
    image: heart,
  },
  penlight: {
    alt: "ペンライトを持ったそぽたん",
    image: penlight,
  },
  kirakira: {
    alt: "目を輝かせているそぽたん",
    image: kirakira,
  },
  sunglasses: {
    alt: "サングラスをかけているそぽたん",
    image: sunglasses,
  },
  crap: {
    alt: "拍手している手",
    image: crap,
  },
};
export const encodeReaction = (reaction: ReactionTypes): string => {
  const id = ReactionKeys.indexOf(reaction);
  if (id === -1) {
    return "";
  }
  return id.toString();
};
export const decodeReaction = (id: string): ReactionTypes | "" => {
  try {
    return ReactionKeys[Number(id)];
  } catch {
    return "";
  }
};
