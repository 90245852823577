import { useMemo } from "react";

import { useAtom, useSetAtom } from "jotai";

import { connectionCountAtom } from "./connection_atom";
import { videoSrcAtom } from "./liveSrcAtom";
import { getReactions } from "./reactions/get_reactions";
import { otherReactionsAtom } from "./reactions/reaction_atom";

import type { ReactionData } from "./reactions/reaction";

import { pollingInterval } from "@/src/consts";

const updateStates = (
  updateReaction: (data: { reactions: Array<ReactionData>; reaction_count: number }) => void,
  updateConnectionCount: (count: number) => void,
  updateVideoSrc: (src: string) => void,
  cursor?: string,
) => {
  let newCursor = cursor;
  getReactions(cursor)
    .then((res) => {
      if (res.reactions.length > 0) {
        // 差分が会った場合のみ更新
        updateReaction(res);
        newCursor = res.reactions[res.reactions.length - 1].id;
      }
      updateConnectionCount(res.connection_count ?? 0);
      updateVideoSrc(res.live_url ?? "");
    })
    .finally(() => {
      setTimeout(updateStates, pollingInterval, updateReaction, updateConnectionCount, updateVideoSrc, newCursor);
    });
};

export const Polling = () => {
  const [, updateReactions] = useAtom(otherReactionsAtom);
  const updateConnectionCount = useSetAtom(connectionCountAtom);
  const updateVideoSrc = useSetAtom(videoSrcAtom);
  useMemo(() => {
    updateStates(updateReactions, updateConnectionCount, updateVideoSrc);
  }, [updateConnectionCount, updateReactions, updateVideoSrc]);
  return <> </>;
};
