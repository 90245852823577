import { ReactionInput } from "./reactionInput";
import { ReactionsView } from "./reactionsView";

export const Reaction = () => {
  return (
    <>
      <ReactionsView />
      <ReactionInput />
    </>
  );
};
