import { useAtomValue } from "jotai";

import { PlayerStatusAtom } from "./playerAtom";

const PlayerDebugger = () => {
  const playerStatus = useAtomValue(PlayerStatusAtom);
  return <pre>{playerStatus.toString()}</pre>;
};

export default PlayerDebugger;
