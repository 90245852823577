import { css, cva } from "@/styled-system/css";

const footerStyle = cva({
  base: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBlockStart: 8,
    paddingBlockEnd: 2,
    paddingX: 8,

    color: "gray.500",

    fontSize: "xs",
  },
  variants: {
    style: {
      PC: {
        background: "none",
        display: "none",
        lg: {
          display: "flex",
        },
      },
      SP: {
        background: "neutral.200",
        lg: {
          display: "none",
        },
      },
    },
  },
});

const listStyle = css({
  display: "flex",
  columnGap: 4,
  paddingBlock: 1,
});

const listItemStyle = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "& span": {
    display: "block",
    fontSize: "0.625rem",
  },
});

const vertifiedPicStyle = css({
  width: 10,
  height: "auto",
  margin: 1,
});

type Props = { style: "PC" | "SP" };

const Footer = ({ style }: Props) => (
  <footer className={footerStyle({ style })}>
    {style === "PC" && (
      <hr
        className={css({
          width: "calc(100% - {spacing.4})",
          color: "neutral.300",
          paddingBlock: 2,
        })}
      />
    )}
    <p>各著作権管理団体の許諾を得て配信しています</p>
    <ul className={listStyle}>
      <li className={listItemStyle}>
        <img src="/nextone_verified_pict.png" alt="NexTone" className={vertifiedPicStyle} />
        <span>許諾番号</span>
        <span>ID000001829</span>
      </li>
      <li className={listItemStyle}>
        <img src="/jasrac_verified_pict.jpg" alt="JASRAC" className={vertifiedPicStyle} />
        <span>許諾番号</span>
        <span>9040258001Y45037</span>
      </li>
    </ul>
    <p className={css({ paddingBlock: "2" })}>
      <a href="/OFL.txt">OFLでライセンスされたCyrealによるフォント"Iceberg"を使用しています</a>
    </p>
    <section className={css({ paddingBlock: "2", display: "flex", flexDirection: "column" })}>
      <p className={css({ display: "flex", flexWrap: "wrap", justifyContent: "center" })}>
        <span>本サイトでは利用状況を把握するためGoogle Analytics及びSentryを利用しています。</span>
        <span>これらのサービスの運営企業に履歴情報が共有される場合があります。</span>
      </p>
      <details className={css({ padding: "4" })}>
        <summary className={css({ width: "fit-content", margin: "0 auto", padding: "2" })}>詳細を見る</summary>
        <div
          className={css({
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "2",
            maxWidth: "300px",
            textAlign: "center",
            margin: "0 auto",
            lg: { maxWidth: "600px" },
          })}
        >
          <p>
            「履歴情報」とは閲覧したページ・利用日時・利用方法・利用者の IP
            アドレスなど利用者が本システムを利用する際の履歴等に関する情報を指します。なお、これらの情報は匿名で収集されており、個人を特定するものではありません。
          </p>
          <p>
            Google
            Analytics及びSentryでの利用状況データの収集のためにCookieを使用しています。これらのサービスによるデータ利用の詳細に関しては、以下のページをご覧ください。
          </p>
          <ul
            className={css({
              textDecoration: "underline",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <li>
              <a href="https://www.google.com/policies/privacy/partners/">
                Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用
              </a>
            </li>
            <li>
              <a href="https://sentry.io/privacy/">Sentryのプライバシーポリシー</a>
            </li>
          </ul>
        </div>
      </details>
    </section>
  </footer>
);

export default Footer;
