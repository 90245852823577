import React, { useEffect, useMemo, useRef, useState } from "react";

import { useAtomValue } from "jotai";
import ReactPlayer from "react-player";

import checkIcon from "./assets/check.svg";
import exitFullscreenIcon from "./assets/exit_fullscreen.svg";
import fullscreenIcon from "./assets/fullscreen.svg";
import loadingSopo from "./assets/loding-sopotan.png";
import muteIcon from "./assets/mute.svg";
import pipIcon from "./assets/pip.svg";
import tuneIcon from "./assets/tune.svg";
import unmuteIcon from "./assets/unmute.svg";
import { videoSrcAtom } from "./liveSrcAtom";

import { css, cx } from "@/styled-system/css";

const controlButtonStyle = css({
  cursor: "pointer",
  width: "30px",
  height: "30px",
  padding: "4px",
});

const resolutionButtonStyle = css({
  display: "flex",
  alignItems: "center",
  gap: 2,
  cursor: "pointer",
  pl: 3,
  pr: 8,
  py: { base: 0.5, sm: 1 },
  transition: "background 0.2s",
  _hover: {
    background: "neutral.400/40",
  },
});

const ContrlollerButton = React.forwardRef<HTMLButtonElement, { src: string; alt: string; onClick: () => void }>(
  (props, ref) => {
    return (
      <button className={controlButtonStyle} onClick={props.onClick} ref={ref}>
        <img src={props.src} alt={props.alt} className={css({ width: "full", height: "full" })} />
      </button>
    );
  },
);

type Props = {
  src: string;
  playerRef: React.MutableRefObject<ReactPlayer | null>;
  volume: number;
  setVolume: (volume: number) => void;
  muted: boolean;
  isPip: boolean;
  setPip: (pip: boolean) => void;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
};

const HlsPlayer = (props: Props) => {
  return (
    <ReactPlayer
      ref={props.playerRef}
      url={props.src}
      autoPlay
      playing={true}
      playsinline={true}
      volume={props.volume}
      muted={props.muted}
      pip={props.isPip}
      width="100%"
      height="100%"
      onEnablePIP={() => {
        props.setPip(true);
      }}
      onDisablePIP={() => {
        props.setPip(false);
      }}
      hlsoptions={{
        debug: true,
        backBufferLength: 0,
        liveMaxLatencyDurationCount: 10,
      }}
      config={{
        file: {
          hlsVersion: "1.5.17",
        },
      }}
    />
  );
};

export default function VideoPlayer() {
  const videoSrc = useAtomValue(videoSrcAtom);
  const [volume, setVolume] = useState<number>(0.8);
  const [isMute, setIsMute] = useState(true);
  const [isPip, setPip] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [shouldShowPlayerControl, setShowPlayerControl] = useState(false);
  const [levels, setLevels] = useState<Array<ResolutionLevel>>([]);
  const [playerStatus, updatePlaerStatus] = useState<Record<string, unknown>>();
  const [resolutionPopupState, setResolutionPopupState] = useState<"hidden" | "hiding" | "visible">("hidden");
  const [currentResolution, setCurrentResolution] = useState(-1);
  const resolutionPopupRef = useRef<HTMLDivElement>(null);
  const resolutionButtonRef = useRef<HTMLButtonElement>(null);

  const playerRef = useRef<HTMLDivElement>(null);
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const updateStreamStatus = useMemo(
    () => (updateInterval: number) => {
      const internalPlayer = reactPlayerRef.current?.getInternalPlayer("hls");
      if (internalPlayer) {
        updatePlaerStatus(internalPlayer.streamController);
      }
      setTimeout(updateStreamStatus, updateInterval, updateInterval);
    },
    [],
  );
  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullScreen(document.fullscreenElement !== null);
    });
    updateStreamStatus(1000);
    fetch("./client_verify");
  }, [updateStreamStatus]);

  useEffect(() => {
    setInterval(() => {
      setPlaying(
        (prev) => prev || reactPlayerRef.current?.getInternalPlayer("hls")?.streamController?._state === "IDLE",
      );
    }, 5000);
  }, []);

  useEffect(() => {
    const handleClickToClose = (event: Event) => {
      const elementPopup = resolutionPopupRef.current;
      const elementButton = resolutionButtonRef.current;
      if (
        resolutionPopupState !== "visible" ||
        elementPopup?.contains(event.target as HTMLElement) ||
        elementButton?.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setResolutionPopupState("hiding");
    };

    window.addEventListener("click", handleClickToClose);
    return () => {
      window.removeEventListener("click", handleClickToClose);
    };
  }, [resolutionPopupState, resolutionPopupRef, resolutionButtonRef]);

  useEffect(() => {
    if (!shouldShowPlayerControl && resolutionPopupState === "visible") {
      setResolutionPopupState("hiding");
    }
  }, [shouldShowPlayerControl, resolutionPopupState]);

  const controlClass = css({
    position: "absolute",
    height: "10%",
    pointerEvents: "auto",
    bottom: "0",
    display: "flex",
    px: "2.5",
    pb: "2",
    alignItems: "flex-end",
    justifyContent: "space-between",
    background: "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%)",
    opacity: 0,
    transition: "all",
    width: "100%",
  });

  useEffect(() => {
    const player = reactPlayerRef.current?.getInternalPlayer();
    player?.addEventListener("playing", () => {
      setPlaying(true);
    });

    player?.addEventListener("suspend", () => {
      setPlaying(false);
    });
  }, [reactPlayerRef.current?.getInternalPlayer()]);

  useEffect(() => {
    let moouseMoveEventId: number | null = null;
    let clickEventId: number | null = null;
    if (playerRef.current) {
      // プレイヤーのコントロールをどのように実現するか
      // マウスが動いたらコントロールを表示する
      // マウスが動いたときにその時点での座標を記録する
      // n秒後にコントロールを非表示にする
      // 予約されているイベントがすでにある場合にはそのイベントをキャンセルし、次のイベントに変更する
      playerRef.current.addEventListener("mousemove", () => {
        clearTimeout(moouseMoveEventId);
        moouseMoveEventId = setTimeout(() => {
          setShowPlayerControl(false);
        }, 3000);
        setShowPlayerControl(true);
      });
      playerRef.current.addEventListener("mouseleave", () => {
        clearTimeout(moouseMoveEventId);
        setShowPlayerControl(false);
      });
      playerRef.current.addEventListener("click", () => {
        clearTimeout(clickEventId);
        clickEventId = setTimeout(() => {
          setShowPlayerControl(false);
        }, 10000);
      });
    }
  }, [playerRef]);

  type ResolutionLevel = {
    index: number;
    res: number;
  };

  const updateResolutionLevels = () => {
    const levelsFromPlayer = reactPlayerRef.current?.getInternalPlayer("hls")?.levels;
    setLevels([]);
    const l = [];
    for (let i = 0; i < levelsFromPlayer?.length; i++) {
      l.push({
        index: i,
        res: levelsFromPlayer[i].height,
      });
    }
    setLevels(l);
  };

  const setResolutionLevel = (levelIndex: number) => {
    if (reactPlayerRef.current?.getInternalPlayer("hls")) {
      reactPlayerRef.current.getInternalPlayer("hls").loadLevel = levelIndex;
    }
  };

  return (
    <>
      <div
        className={cx(
          css({
            width: "100%",
            height: "100%",
            background: "#f5f5f5",
            objectFit: "contain",
            cursor: "none",
            position: "relative",
            lg: {
              borderRadius: "lg",
            },
            overflow: "hidden",
          }),
          shouldShowPlayerControl &&
            css({
              cursor: "default",
              "& .player-controls": {
                opacity: 1,
              },
              transition: "opacity",
            }),
        )}
        ref={playerRef}
      >
        <HlsPlayer
          src={videoSrc}
          playerRef={reactPlayerRef}
          volume={isMute ? 0 : volume}
          muted={isMute}
          setVolume={setVolume}
          isPip={isPip}
          setPip={setPip}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
        {!isPlaying && (
          <div
            className={css({
              position: "absolute",
              top: 0,
              left: 0,
              width: "full",
              height: "full",
              display: "flex",
              flexDir: "column",
              color: "gray.400",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none",
            })}
          >
            <img
              src={loadingSopo}
              alt="休憩中のそぽたんの画像"
              className={css({ height: 28, animation: "loadingSopotan 1s infinite alternate ease-in-out" })}
            />
            <span>LOADING…</span>
          </div>
        )}
        {isMute && (
          <button
            onClick={() => setIsMute(false)}
            className={css({
              animation: "1s umuteDissapear 5s forwards",
              position: "absolute",
              top: 4,
              fontWeight: "bold",
              right: 3,
              paddingX: 5,
              paddingY: 2,
              borderRadius: "lg",
              background: "black",
              opacity: 0.5,
              color: "white",
              zIndex: 5,
              cursor: "pointer",
              display: "flex",
            })}
          >
            <img src={muteIcon} />
            <span>ミュートを解除</span>
          </button>
        )}
        {/* Controls */}
        <div className={cx(controlClass, "player-controls")}>
          <div
            className={css({
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <button
              onClick={() => {
                reactPlayerRef.current?.seekTo(1);
              }}
              className={css({
                color: "white",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: "1",
                pr: 1.5,
                paddingBottom: "3px",
                fontFamily: "ZenKakuGothicAntique",
                _before: {
                  content: '""',
                  display: "block",
                  width: 3,
                  height: 3,
                  background: isPlaying ? "orange" : "white",
                  borderRadius: "full",
                  marginTop: "3px",
                },
              })}
            >
              LIVE
            </button>
            <div className={css({ marginRight: 1, display: "flex", alignItems: "center" })}>
              <ContrlollerButton
                src={isMute ? muteIcon : unmuteIcon}
                alt={isMute ? "ミュートする" : "ミュート解除する"}
                onClick={() => setIsMute((prev) => !prev)}
              />
            </div>
            {!isMute && (
              <input
                type="range"
                id="volume"
                name="volume"
                min="0"
                max="1"
                step={0.1}
                value={volume}
                onChange={(e) => {
                  setVolume(Number(e.target.value) as unknown as number);
                }}
                className={css({
                  appearance: "none",
                  background: "transparent",
                  cursor: "pointer",
                  "&::-webkit-slider-runnable-track": {
                    background: "#d1d1d1",
                    height: 2,
                    borderRadius: "full",
                    overflow: "hidden",
                  },
                  "&::-moz-range-track": {
                    background: "#d1d1d1",
                    height: 2,
                    borderRadius: "full",
                  },
                  "&::-webkit-slider-thumb": {
                    appearance: "none",
                    width: 0,
                    height: 2,
                    borderRadius: "full",
                    background: "#000",
                    boxShadow: "-100px 0 0 100px #FFF",
                  },
                  "&::-moz-slider-thumb": {
                    appearance: "none",
                    width: 0,
                    height: 2,
                    borderRadius: "full",
                    background: "#000",
                    boxShadow: "-100px 0 0 100px #FFF",
                  },
                })}
              />
            )}
          </div>
          <div className={css({ display: "flex", alignItems: "center" })}>
            <ContrlollerButton
              onClick={() => {
                setResolutionPopupState((prev) => {
                  if (prev !== "visible") {
                    updateResolutionLevels();
                    return "visible";
                  } else {
                    return "hiding";
                  }
                });
              }}
              src={tuneIcon}
              alt="画質変更"
              ref={resolutionButtonRef}
            />
            {ReactPlayer && (
              <ContrlollerButton
                onClick={() => {
                  setPip(true);
                }}
                src={pipIcon}
                alt="ピクチャ・イン・ピクチャ"
              />
            )}
            <ContrlollerButton
              onClick={() => {
                if (isFullScreen) {
                  document.exitFullscreen();
                } else {
                  const player = reactPlayerRef.current?.getInternalPlayer();
                  const uiPlayer = playerRef.current;
                  if (uiPlayer?.requestFullscreen) {
                    uiPlayer.requestFullscreen({ navigationUI: "hide" });
                  } else if (player?.webkitRequestFullscreen) {
                    player.webkitRequestFullscreen();
                  } else if (player?.mozRequestFullScreen) {
                    player?.mozRequestFullScreen();
                  } else if (player?.msRequestFullscreen) {
                    player?.msRequestFullscreen();
                  } else if (player?.webkitEnterFullscreen) {
                    player?.webkitEnterFullscreen();
                    player?.addEventListener("webkitpresentationmodechanged", () => {
                      setIsFullScreen(document.fullscreenElement !== null);
                    });
                  } else {
                    player?.requestFullscreen({ navigationUI: "hide" });
                  }
                  player?.addEventListener("fullscreenchange", () => {
                    setIsFullScreen(document.fullscreenElement !== null);
                  });
                }
                setIsFullScreen((prev) => !prev);
              }}
              src={isFullScreen ? exitFullscreenIcon : fullscreenIcon}
              alt={isFullScreen ? "全画面解除" : "全画面"}
            />
          </div>
        </div>
        {resolutionPopupState !== "hidden" && (
          <div
            className={css(
              {
                position: "absolute",
                right: "5%",
                bottom: { base: "40px", sm: "50px" },
                background: "neutral.800/80",
                color: "white",
                fontSize: { base: "xs", sm: "sm" },
                py: { base: 0.5, sm: 2 },
                borderRadius: "md",
                animationName: "fadeIn",
                animationDuration: "0.2s",
                animationFillMode: "forwards",
              },
              resolutionPopupState === "hiding"
                ? {
                    animationName: "fadeOut",
                  }
                : {},
            )}
            ref={resolutionPopupRef}
            onAnimationEnd={() => {
              if (resolutionPopupState === "hiding") {
                setResolutionPopupState("hidden");
              }
            }}
          >
            <ul>
              {[...levels, { res: "auto", index: -1 }].map((l) => (
                <li
                  className={resolutionButtonStyle}
                  key={`resolution_${l.res}`}
                  aria-current={currentResolution === l.index}
                  onClick={() => {
                    setCurrentResolution(l.index);
                    setResolutionLevel(l.index);
                    setResolutionPopupState("hiding");
                  }}
                >
                  {currentResolution === l.index ? (
                    <img
                      src={checkIcon}
                      alt=""
                      className={css({
                        width: "1em",
                        color: "white",
                      })}
                    />
                  ) : (
                    <span
                      className={css({
                        display: "inline-block",
                        width: "1em",
                      })}
                    />
                  )}
                  <span>{l.res === "auto" ? "自動" : `${l.res}p`}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {window.location.search === "?debug" && (
        <>
          <pre>{JSON.stringify(playerStatus?.streamController)}</pre>
          <button
            onClick={() => {
              if (reactPlayerRef.current?.getInternalPlayer("hls")) {
                reactPlayerRef.current.getInternalPlayer("hls").loadLevel = -1;
              }
            }}
          >
            auto
          </button>
          {levels.map((level, index) => {
            return (
              <div key={index}>
                <button
                  onClick={() => {
                    setResolutionLevel(level.index);
                  }}
                >
                  {level.res}
                </button>
              </div>
            );
          })}
          <div />
          <button onClick={updateResolutionLevels}>settings</button>
        </>
      )}
    </>
  );
}
