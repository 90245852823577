export const siteUrl = "";

export const taiyakiUrl = "https://taiyaki.sohosai.com";
export const taiyakiTimetableUrl = "https://taiyaki.sohosai.com/timetable";
export const taiyakiProjectBaseUrl = "https://taiyaki.sohosai.com/projects/";

export const siteDescription =
  "筑波大学の学園祭「雙峰祭」をライブで生配信しています！！UNITED ステージをおうちから、会社から、どこからでも！いっぱい観て盛り上げてください🔥";
export const xShareText = "雙峰祭のステージ生配信を視聴しています！\n#雙峰祭\nhttps://live.sohosai.com/";

export const pollingInterval = 1000;
export const sopotanGaudgeMax = 500;
// バックエンドの PAGINATION_LIMIT 以上にする
export const displayLimit = 30;
