import { ReactionKeys, type ReactionTypes } from "./reaction.ts";
import { ReactionContent } from "./reactionContent.tsx";

import { css } from "@/styled-system/css";

export const ReactionSelector = (props: {
  onSelect: (e: ReactionTypes) => void;
  onRemove: () => void;
  pressedKey: string | null;
  disabled?: boolean;
}) => {
  return (
    <div className={css({ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" })}>
      <div
        className={css({
          background: "gray.200",
          display: "flex",
          rounded: "full",
          px: 6,
          py: 2,
          height: 16,
          justifyContent: "space-around",
          lg: { px: 10 },
        })}
      >
        {ReactionKeys.map((key, idx) => (
          <button
            onClick={() => props.onSelect(key)}
            key={key}
            className={css({
              cursor: props.disabled ? "default" : "pointer",
              objectFit: "contain",
              height: "full",
              filter: "auto",
              brightness:
                props.disabled ||
                (props.pressedKey?.startsWith("Digit") && parseInt(props.pressedKey.slice(-1)) === idx + 1)
                  ? 0.5
                  : undefined,
              _active: { brightness: 0.5 },
            })}
          >
            <ReactionContent
              reaction={key}
              className={css({
                objectFit: "contain",
                height: "full",
                filter: "auto",
              })}
            />
          </button>
        ))}
      </div>
      <img
        src="/backspace.svg"
        alt="バックスペース"
        className={css({
          height: 14,
          cursor: "pointer",
          filter: "auto",
          brightness: props.pressedKey === "Backspace" ? 0.5 : undefined,
          _active: { brightness: 0.5 },
        })}
        onClick={props.onRemove}
      />
    </div>
  );
};
