import { useEffect, useRef, useState } from "react";

import timetable from "@/src/assets/timetable.json";
import { taiyakiProjectBaseUrl, taiyakiTimetableUrl } from "@/src/consts";
import { getJSTDate, getTimeString } from "@/src/lib";
import { css, cva } from "@/styled-system/css";

const ttConsts = {
  width: 1000,
  gap: 60,
  minuteLineMargin: 15,

  strokeWidth: 1,
  strokeWidthBold: 2,

  blockMargin: 10,
  leftMargin: 25,
} as const;

type TtConsts = typeof ttConsts;
const calcPosition = (time: string, ttConsts: TtConsts, startTime: number): number => {
  const [hours, minutes] = time.split(":").map((v) => Number(v));
  return ttConsts.blockMargin + (((hours - startTime) * 60 + minutes) / 10) * ttConsts.gap + ttConsts.strokeWidthBold;
};

const calcHeight = (startTime: string, endTime: string, ttConsts: TtConsts): number => {
  const [startHours, startMinutes] = startTime.split(":").map((v) => Number(v));
  const [endHours, endMinutes] = endTime.split(":").map((v) => Number(v));
  return (
    ((endHours * 60 + endMinutes - (startHours * 60 + startMinutes)) / 10) * ttConsts.gap - ttConsts.strokeWidthBold
  );
};

const calcEndHour = (time: { startsAt: string; endsAt: string } | undefined): number => {
  if (time?.endsAt) {
    // 終了時刻が存在するとき
    const [endHours, endMinutes] = time.endsAt.split(":").map((v) => Number(v));
    // 終了時刻が生時でないときは時間に+1し、正時のときはそのまま返す
    return endMinutes !== 0 ? endHours + 1 : endHours;
  }

  // 終了時刻が存在しなければ開始時刻+1と仮定して返す
  return Number(time?.startsAt.split(":")[0]) + 1;
};

const calcShouldShowTimeDisplay = (
  now: Date,
  date: string,
  todaysTimetable: (typeof timetable)[number] | undefined,
) => {
  const startsAt = todaysTimetable?.performances[0].time.startsAt;
  const lastPerformanceTime = todaysTimetable?.performances.at(-1)?.time;
  const endsAt = `${calcEndHour({ startsAt: lastPerformanceTime?.startsAt || "", endsAt: lastPerformanceTime?.endsAt || "" })}:00`;

  const startDate = new Date(`${date}T${startsAt}`);
  const endDate = new Date(`${date}T${endsAt}`);

  return startDate <= now && now <= endDate;
};

const TimeTable = () => {
  const timetableContentStyle = cva({
    base: {
      height: "xl",
      overflowY: "auto",
      position: "relative",
      backgroundColor: "white",
      borderBottomRadius: "xl",
      paddingBlockStart: 3,
      boxShadow: "0px 4px 4px 0px #00000040",
      "@supports not selector(::-webkit-scrollbar)": {
        scrollbarColor: "token(colors.sohosai.orange) token(colors.neutral.100)",
      },
      _scrollbar: { width: "10px" },
      _scrollbarThumb: { borderRadius: "9999px", background: "#ed6d1f" },
      _scrollbarTrack: { borderBottomRightRadius: "9999px", background: "#F5F5F5" },
    },
    variants: {
      noContent: {
        true: {
          height: "auto",
        },
      },
    },
  });

  const timetableRef = useRef<HTMLDivElement>(null);

  const [now, setNow] = useState(() => getJSTDate(new Date()));
  const [shouldShowTimeDisplay, setShowTimeDisplay] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(24);
  const [date, setDate] = useState(() => `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`);

  useEffect(() => {
    const now = getJSTDate(new Date());
    const date = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
    const todaysTimetable = timetable.find((v) => v.date === date);
    const startTime = Number(todaysTimetable?.performances[0].time.startsAt.split(":")[0]);
    setStartTime(startTime);
    setEndTime(calcEndHour(todaysTimetable?.performances.at(-1)?.time));

    // 全部レンダリングされてからやる
    setTimeout(() => {
      const scrollTarget = todaysTimetable?.performances.reduce((acc, current) => {
        const accDate = new Date(`${date}T${acc.time.startsAt}`);
        const currentDate = new Date(`${date}T${current.time.startsAt}`);

        const accDiff = now.getTime() - accDate.getTime();
        const currentDiff = now.getTime() - currentDate.getTime();

        // 対象が現在時刻よりも前に始まって、かつ前の候補よりも現在時刻との差分が小さかったら更新
        return currentDiff >= 0 && accDiff >= currentDiff ? current : acc;
      });
      if (scrollTarget) {
        const targetPositionY = calcPosition(scrollTarget.time.startsAt, ttConsts, startTime);
        timetableRef.current?.scrollTo({ top: targetPositionY });
      }
    });

    const intervalId = setInterval(() => {
      setNow(getJSTDate(new Date()));
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const date = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;
    setDate(date);

    const todaysTimetable = timetable.find((v) => v.date === date);
    setStartTime(Number(todaysTimetable?.performances[0].time.startsAt.split(":")[0]));
    setEndTime(calcEndHour(todaysTimetable?.performances.at(-1)?.time));

    setShowTimeDisplay(calcShouldShowTimeDisplay(now, date, todaysTimetable));
  }, [now]);

  if (!timetable.some((v) => v.date === date)) {
    return (
      <div className={timetableContentStyle({ noContent: true })} ref={timetableRef}>
        <div className={css({ display: "flex", justifyContent: "center" })}>
          <p className={css({ height: "xs", fontSize: "lg", color: "neutral.600" })}>本日の配信はありません</p>
        </div>
        <ShowMoreButton date={null} text="全ての日程のタイムテーブルを見る" />
      </div>
    );
  }
  return (
    <>
      <div className={timetableContentStyle()} ref={timetableRef}>
        <div
          className={css({
            position: "absolute",
            width: "calc(100% - 3rem)",
            height: "fit-content",
            marginInline: 6,
            overflowX: "hidden",
          })}
        >
          <svg
            width={ttConsts.width}
            height={((endTime - startTime) * 6 + 1) * ttConsts.gap + ttConsts.strokeWidthBold + ttConsts.blockMargin}
            viewBox={`0 0 ${ttConsts.width} ${((endTime - startTime) * 6 + 1) * ttConsts.gap + ttConsts.strokeWidthBold + ttConsts.blockMargin}`}
            stroke="#969696"
            strokeLinecap="round"
            strokeWidth={ttConsts.strokeWidth}
            xmlns="http://wwww3.org/2000/svg"
          >
            {[...Array(endTime - startTime)].flatMap((_, j) => {
              return [...Array(6)].flatMap((_, i) =>
                i === 0 ? (
                  <>
                    <text
                      x="0"
                      y={ttConsts.blockMargin + j * 6 * ttConsts.gap + ttConsts.strokeWidthBold / 2}
                      dominantBaseline="middle"
                      fill="#4C4C4C"
                      stroke="#4C4C4C"
                      fontFamily="iceberg"
                    >
                      {startTime + j}
                    </text>
                    <path
                      d={`M${ttConsts.leftMargin + ttConsts.strokeWidthBold / 2} ${ttConsts.blockMargin + j * 6 * ttConsts.gap + ttConsts.strokeWidthBold / 2}H${ttConsts.width - ttConsts.strokeWidthBold / 2}`}
                      strokeWidth={ttConsts.strokeWidthBold}
                    />
                  </>
                ) : (
                  <path
                    d={`M${ttConsts.leftMargin + ttConsts.minuteLineMargin + ttConsts.strokeWidth / 2} ${ttConsts.blockMargin + j * 6 * ttConsts.gap + i * ttConsts.gap + ttConsts.strokeWidth / 2}H${ttConsts.width - ttConsts.strokeWidth / 2}`}
                  />
                ),
              );
            })}
            <text
              x="0"
              y={ttConsts.blockMargin + (endTime - startTime) * 6 * ttConsts.gap + ttConsts.strokeWidthBold / 2}
              dominantBaseline="middle"
              fill="#4C4C4C"
              stroke="#4C4C4C"
              fontFamily="iceberg"
            >
              {endTime}
            </text>
            <path
              d={`M${ttConsts.leftMargin + ttConsts.strokeWidthBold / 2} ${ttConsts.blockMargin + (endTime - startTime) * 6 * ttConsts.gap + ttConsts.strokeWidthBold / 2}H${ttConsts.width - ttConsts.strokeWidthBold / 2}`}
              strokeWidth={ttConsts.strokeWidthBold}
            />
          </svg>
        </div>
        <div className={css({ position: "relative", width: `calc(100% - 40px - 7% - 3rem)` })}>
          {timetable
            .find((tt) => tt.date === date)
            ?.performances.map((performance) => {
              return (
                <Performance
                  name={performance.projectName}
                  url={`${taiyakiProjectBaseUrl}${performance.projectIndex}`}
                  date={date}
                  startTime={performance.time.startsAt}
                  endTime={performance.time.endsAt}
                  position={calcPosition(performance.time.startsAt, ttConsts, startTime)}
                  height={calcHeight(performance.time.startsAt, performance.time.endsAt, ttConsts)}
                  noStreaming={performance.noStreaming || false}
                />
              );
            })}
        </div>
        <div
          className={css({
            position: "absolute",
            zIndex: 10,
            height: "4px",
            width: "calc(100% - {spacing.12})",
            marginBlockStart: 2,
            marginInline: 6,
            borderRadius: "full",
            background: "sohosai.orange",
            _before: {
              position: "absolute",
              content: "''",
              background: "sohosai.orange",
              display: "block",
              height: 4,
              width: 4,
              left: 0,
              top: "calc({spacing.4} / -2 + 4px / 2 )",
              clipPath: "polygon(0% 0%, 100% 50%, 0% 100%)",
            },
            _after: {
              position: "absolute",
              content: "''",
              background: "sohosai.orange",
              display: "block",
              height: 4,
              width: 4,
              right: 0,
              top: "calc({spacing.4} / -2 + 4px / 2 )",
              clipPath: "polygon(100% 0%, 0% 50%, 100% 100%)",
            },
          })}
          style={{
            top: `${calcPosition(getTimeString(now), ttConsts, startTime)}px`,
            display: !shouldShowTimeDisplay ? "none" : undefined,
          }}
        />
      </div>
      <ShowMoreButton date={date} text="すべて表示する" />
    </>
  );
};

const ShowMoreButton = ({ date, text }: { date: string | null; text: string }) => {
  return (
    <div className={css({ position: "absolute", bottom: 4, right: 4 })}>
      <a
        href={date ? `${taiyakiTimetableUrl}?day=${date}` : taiyakiTimetableUrl}
        className={css({
          position: "relative",
          backgroundColor: "sohosai.orange",
          color: "white",
          fontWeight: "bold",
          borderRadius: "full",
          paddingInline: 5,
          paddingBlock: 1,
          boxShadow: "md",
          zIndex: 20,
        })}
      >
        ≫{text}
      </a>
    </div>
  );
};

type PerformanceProps = {
  /** 企画名 */
  name: string;
  /** taiyakiなどへのリンク */
  url: string;
  /** 日付。YYYY-MM-DDのフォーマットで */
  date: string;
  /** 開始時刻。HH:mmのフォーマットで */
  startTime: string;
  /** 終了時刻。HH:mmのフォーマットで */
  endTime: string;
  /** 配信がないかどうか */
  noStreaming: boolean;
  /** 位置のy座標。pxで指定 */
  position: number;
  /** 高さ。pxで指定 */
  height: number;
};

const Performance = ({ name, url, date, startTime, endTime, noStreaming, position, height }: PerformanceProps) => {
  if (!height) {
    height = ttConsts.gap * 3;
  }
  if (height < 30) {
    return;
  }

  const startIso = `${date}T${startTime}`;
  const endIso = `${date}T${endTime}`;

  const timetableItemStyle = cva({
    base: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    variants: {
      small: {
        true: {
          flexDirection: "row",
        },
      },
    },
  });
  const timetableTextStyle = cva({
    base: { color: "neutral.700", fontSize: "md", fontWeight: "bold", textWrap: "wrap", marginInline: 2 },
    variants: {
      small: {
        true: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },
  });
  const timetableTimeStyle = cva({
    base: {
      color: "neutral.500",
      fontSize: "sm",
      marginBlock: 1,
      marginInline: 2,
    },
    variants: {
      smallest: {
        true: {
          display: "none",
        },
      },
    },
  });
  const timetableHrStyle = cva({
    base: { borderWidth: "0.5px", color: "sohosai.blue" },
    variants: {
      smallest: {
        true: {
          display: "none",
        },
      },
    },
  });

  return (
    <div
      className={css({
        width: "100%",
        position: "absolute",
        backgroundColor: "white",
        padding: 2,
        marginLeft: "calc(40px + 5% + 1.5rem)",

        borderRadius: "xl",

        borderColor: "sohosai.blue",
        borderWidth: "3px",
        borderStyle: "solid",
      })}
      style={{ top: `${position}px`, height: `${height}px` }}
    >
      <div
        className={css({
          height: "100%",
          borderRadius: "lg",
          paddingInline: 2,
          display: "flex",
          flexDirection: "column",

          borderColor: "inherit",
          borderWidth: "1px",
          borderStyle: "solid",
        })}
      >
        <p className={timetableTimeStyle({ smallest: height < 80 })}>
          <span>
            <time dateTime={startIso}>{startTime}</time>〜<time dateTime={endIso}>{endTime}</time>
          </span>
          {noStreaming && (
            <span
              className={css({
                background: "sohosai.orange",
                borderRadius: "sm",
                px: "1",
                marginInline: "3",
                color: "white",
              })}
            >
              配信なし
            </span>
          )}
        </p>
        <hr className={timetableHrStyle({ smallest: height < 80 })} />
        <div className={timetableItemStyle({ small: height < 100 })}>
          <h3 className={timetableTextStyle({ small: height < 120 })}>{name}</h3>
          <div className={css({ alignSelf: "flex-end", marginBlockEnd: 2 })}>
            <a
              href={url}
              target="_blank"
              rel="noopener"
              className={css({ color: "sohosai.blue", fontWeight: "bold", marginInline: 2 })}
            >
              ≫MORE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
