import { useEffect, useState } from "react";

import { useMatchMedia } from "../hooks";

import bgSopotan from "./assets/loding-sopotan.png";

import { taiyakiUrl } from "@/src/consts";
import { css, cva } from "@/styled-system/css";
import { token } from "@/styled-system/tokens";

const iconWidth = "1.25rem";
const iconHeight = "1.125rem";
const iconLineStroke = "3px";
const menuButtonStyle = css({
  cursor: "pointer",
  display: "block",
  lg: {
    display: "none",
  },

  "& div": {
    position: "relative",
    width: `${iconWidth}`,
    height: `${iconHeight}`,
  },
  "& span": {
    position: "absolute",
    display: "block",
    height: `${iconLineStroke}`,
    width: `${iconWidth}`,
    borderRadius: "full",
    backgroundColor: "#4C4C4C",

    transition: "all 125ms ease-out",
  },

  "& span:nth-child(1)": {
    top: 0,
    transformOrigin: "left center",
  },
  "& span:nth-child(2)": {
    top: `calc((${iconHeight} - (${iconLineStroke} * 3)) / 2 + ${iconLineStroke})`,
  },
  "& span:nth-child(3)": {
    top: `calc(${iconHeight} - ${iconLineStroke})`,
    transformOrigin: "left center",
  },

  "& .open": {
    "& span:nth-child(1)": {
      //top: 0,
      top: `calc(sin(calc(90deg + atan2(${iconHeight}, ${iconWidth}))) * ${iconLineStroke} / -2)`,
      transform: `rotate(atan2(${iconHeight}, ${iconWidth}))`,
      width: `hypot(${iconWidth}, ${iconHeight})`,
    },
    "& span:nth-child(2)": {
      opacity: 0,
    },
    "& span:nth-child(3)": {
      top: `calc(${iconHeight} - ${iconLineStroke} + sin(calc(90deg + atan2(${iconHeight}, ${iconWidth}))) * ${iconLineStroke} / 2)`,
      transform: `rotate(calc(0deg - atan2(${iconHeight}, ${iconWidth})))`,
      width: `hypot(${iconWidth}, ${iconHeight})`,
    },
  },
});

const navigationStyle = cva({
  base: {
    position: "fixed",
    background: "white",
    width: "full",
    height: "full",
    paddingInline: 4,
    paddingBlock: 2,
    alignSelf: "flex-end",

    flexShrink: 0,
    transition: "max-height 0.25s ease-out, padding 0.25s ease-out",
    overflow: "hidden",
    maxHeight: "150%",
    zIndex: 300,

    lg: {
      position: "initial",
      alignSelf: "unset",
      width: "unset",
      height: "unset",

      paddingInline: 4,
      paddingBlock: 0,
    },
  },
  variants: {
    hidden: {
      true: {
        maxHeight: 0,
        paddingBlock: 0,

        lg: {
          maxHeight: "unset",
        },
      },
    },
  },
});

const navigationMenuStyle = css({
  display: "flex",
  flexDirection: "column",
  height: "full",
  py: 24,
  px: 10,

  rowGap: 20,

  fontWeight: "bold",
  color: "sohosai.orange",
  fontSize: 20,

  lg: {
    flexDirection: "row",
    columnGap: 4,
    p: 0,
    fontSize: "unset",
  },
});

const navigationMenuGroup = cva({
  base: {
    display: "flex",
    flexDirection: "column",
    rowGap: 10,

    lg: {
      display: "contents",
    },
  },
  variants: {
    hiddenWhenPC: {
      true: {
        lg: {
          display: "none",
        },
      },
    },
  },
});

const navigationMenuLabelStyle = css({
  color: "#AFAFAF",
  lg: {
    display: "none",
  },
});

const navigationMenuLinkStyle = css({
  position: "relative",
  paddingInlineStart: 4,
  display: "flex",
  flexDir: "column",
  justifyContent: "center",
  my: 1,

  lg: {
    padding: 1,
    my: 0,
  },

  _before: {
    position: "absolute",
    content: '""',
    display: "block",
    borderRadius: "full",
    background: "sohosai.orange",

    left: 0,
    height: "120%",
    width: "3px",
    lg: {
      left: 0,
      bottom: 0,
      height: "2px",
      width: "100%",
    },
  },
});

const Header = () => {
  const navigationListId = "navigation-list";

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isPCWidth = useMatchMedia(`(min-width : ${token("breakpoints.lg")})`);

  useEffect(() => {
    if (isMenuOpen && !isPCWidth) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen, isPCWidth]);

  return (
    <header
      aria-expanded={isMenuOpen}
      className={css({
        backgroundColor: "white",
        height: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        shadow: "lg",
        flexDirection: "column",
        paddingInline: 0,

        lg: {
          flexDirection: "row",
          paddingInline: 6,
          height: 20,
        },
      })}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          flexShrink: 0,

          width: "100%",
          justifyContent: "space-between",
          height: 16,
          paddingInline: 4,
          backgroundColor: "white",
          zIndex: 310,

          lg: {
            width: "auto",
            height: "auto",
            paddingInline: 0,
          },
        })}
      >
        <div className={css({ display: "flex", alignItems: "center", gap: 2, lg: { gap: 4 } })}>
          <img src="/logo-small.png" alt="" className={css({ height: 10, width: "auto" })} />
          <h1
            className={css({
              color: "sohosai.orange",
              fontSize: "18px",
              textAlign: "center",
              lg: { fontSize: "30px" },
            })}
          >
            <span className={css({ fontFamily: "ZenKakuGothicAntique", fontWeight: 900 })}>雙峰祭</span>
            <span className={css({ fontFamily: "ZenDots" })}>UNITED</span>
            <span className={css({ fontFamily: "ZenKakuGothicAntique", fontWeight: 900 })}>ステージ生配信</span>
          </h1>
        </div>
        <button
          type="button"
          aria-haspopup="menu"
          aria-controls={navigationListId}
          aria-label={isMenuOpen ? "メニューを開く" : "メニューを閉じる"}
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
          className={menuButtonStyle}
        >
          <div aria-hidden="true" className={isMenuOpen ? "open" : ""}>
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      <nav id={navigationListId} className={navigationStyle({ hidden: !isPCWidth && !isMenuOpen })}>
        <ul className={navigationMenuStyle} aria-hidden={!isPCWidth && !isMenuOpen}>
          <ul className={navigationMenuGroup()}>
            <span className={navigationMenuLabelStyle}>各種リンク</span>
            <li className={navigationMenuLinkStyle}>
              <a href="https://sohosai.com">雙峰祭公式サイト</a>
            </li>
            <li className={navigationMenuLinkStyle}>
              <a href={taiyakiUrl}>企画検索システム</a>
            </li>
          </ul>
          <ul className={navigationMenuGroup({ hiddenWhenPC: true })}>
            <span className={navigationMenuLabelStyle}>雙峰祭公式SNS</span>
            <li className={navigationMenuLinkStyle}>
              <a href="https://x.com/sohosai">X (旧Twitter)</a>
            </li>
            <li className={navigationMenuLinkStyle}>
              <a href="https://instagram.com/sohosai">Instagram</a>
            </li>
          </ul>
        </ul>
        <img
          src={bgSopotan}
          alt=""
          className={css({
            display: "block",
            position: "absolute",
            opacity: 0.1,
            bottom: 0,
            right: "-20%",
            width: "80vw",
            maxHeight: "600px",
            objectFit: "contain",
            lg: {
              display: "none",
            },
          })}
        />
      </nav>
    </header>
  );
};

export default Header;
