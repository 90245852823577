import { atom } from "jotai";

import { postReaction } from "./post_reaction";

import type { ReactionData, ReactionTypes } from "./reaction";

import { displayLimit, pollingInterval } from "@/src/consts";

export const reactionInputAtom = atom<Array<ReactionTypes>>([]);

type ReactionDataType = {
  reactions: Map<string, { content: Array<string>; postBy: "self" | "other"; isFirst?: boolean }>;
  reaction_count: number;
};

const reactionDataAtom = atom({
  reactions: new Map(),
  reaction_count: 0,
} satisfies ReactionDataType);

export const reactionCountAtom = atom((get) => get(reactionDataAtom).reaction_count);
export const reactionsAtom = atom((get) =>
  Array.from(get(reactionDataAtom).reactions, ([id, { content, postBy, isFirst }]) => ({
    id,
    content,
    postBy,
    isFirst,
  })),
);

const randomizePollingAtom = atom(null, (get, set, newReactionData: ReactionDataType) => {
  const randomPoints = Array.from({ length: newReactionData.reactions.size }, () =>
    Math.floor(Math.random() * (pollingInterval + 1)),
  ).sort((a, b) => b - a);

  newReactionData.reactions.forEach((reaction, id) => {
    const randomPoint = randomPoints.pop();

    const addData = () => {
      const reactionData = get(reactionDataAtom);
      const newState = new Map(reactionData.reactions);
      newState.set(id, { content: reaction.content, postBy: reaction.postBy });
      set(reactionDataAtom, {
        reactions: newState,
        reaction_count: reactionData.reaction_count + 1,
      });
    };

    setTimeout(addData, randomPoint);
  });

  // 最後に整合性を取る
  setTimeout(() => {
    const reactionData = get(reactionDataAtom);
    set(reactionDataAtom, {
      reactions: new Map([...reactionData.reactions, ...newReactionData.reactions].slice(-displayLimit)),
      reaction_count: Math.max(reactionData.reaction_count, newReactionData.reaction_count),
    });
  }, pollingInterval);
});

export const otherReactionsAtom = atom(
  null,
  (
    get,
    set,
    action: {
      reactions: Array<ReactionData>;
      reaction_count: number;
    },
  ) => {
    const reactionData = get(reactionDataAtom);
    const newReactionData: ReactionDataType = {
      reactions: new Map(),
      reaction_count: action.reaction_count,
    };
    action.reactions.forEach((reaction) => {
      if (!reactionData.reactions.has(reaction.id)) {
        newReactionData.reactions.set(reaction.id, {
          content: reaction.content,
          postBy: "other",
          isFirst: reactionData.reactions.size === 0,
        });
      }
    });

    if (reactionData.reactions.size === 0) {
      set(reactionDataAtom, newReactionData);
    } else {
      set(randomizePollingAtom, newReactionData);
    }
  },
);

export const selfReactionAtom = atom(null, (get, set, action: ReactionData) => {
  const state = get(reactionDataAtom);
  const newState = new Map(state.reactions);
  newState.set(action.id, { content: action.content, postBy: "self" });
  set(reactionDataAtom, {
    reactions: new Map([...newState].slice(-displayLimit)),
    reaction_count: state.reaction_count + 1,
  });
});

export const selectReactionAtom = atom(null, (get, set, e: ReactionTypes) => {
  const reactionInput = get(reactionInputAtom);
  set(reactionInputAtom, [...reactionInput, e].slice(0, 10));
});

export const removeReactionAtom = atom(null, (get, set) => {
  const reactionInput = get(reactionInputAtom);
  set(reactionInputAtom, reactionInput.slice(0, -1));
});

export const postReactionAtom = atom(null, (get, set) => {
  const reactionInput = get(reactionInputAtom);
  postReaction(reactionInput).then((res) => {
    set(reactionInputAtom, []);
    set(selfReactionAtom, res);
  });
});
