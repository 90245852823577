import { useState } from "react";

import { type ReactionTypes } from "./reaction";
import { ReactionContent } from "./reactionContent";

import { css, cva, cx } from "@/styled-system/css";
import { token } from "@/styled-system/tokens";

type Reaction = {
  keyboard: string;
  reaction_key: ReactionTypes;
};

const reactions: Array<Reaction> = [
  {
    keyboard: "1",
    reaction_key: "heart",
  },
  {
    keyboard: "2",
    reaction_key: "penlight",
  },
  {
    keyboard: "3",
    reaction_key: "kirakira",
  },
  {
    keyboard: "4",
    reaction_key: "sunglasses",
  },
  {
    keyboard: "5",
    reaction_key: "crap",
  },
];

const kbdStyle = cva({
  base: {
    display: "inline-block",
    position: "relative",
    color: "neutral.400",
    lineHeight: 1,
    borderRadius: "sm",
    zIndex: 0,
    outline: "2px solid {colors.neutral.400}",
    _after: {
      display: "block",
      content: '""',
      position: "absolute",
      borderRadius: "sm",
      background: "white",
      zIndex: -1,
    },
  },
  variants: {
    size: {
      sm: {
        width: 8,
        height: 8,
        px: 1,
        py: 1,
        _after: {
          top: 0.5,
          left: 0.5,
          width: "calc({spacing.8} + 3px)",
          height: "calc({spacing.8} + 3px)",
        },
      },
      lg: {
        width: 10,
        height: 10,
        px: 2,
        py: 2,
        _after: {
          top: 0.5,
          left: 0.5,
          width: "calc({spacing.10} + 4px)",
          height: "calc({spacing.10} + 4px)",
        },
      },
    },
  },
});

const controlItemStyle = css({ display: "flex", alignItems: "center" });
const labelStyle = css({ fontSize: "sm", fontWeight: "bold" });

export const ReactionInformation = () => {
  const [isFocus, setIsFocus] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <div className={css({ position: "relative", zIndex: 100, display: "flex", flexDir: "column", my: 2 })}>
      <div
        className={css({
          borderRadius: "full",
          backgroundColor: "neutral.300",
          color: "white",
          fontSize: "lg",
          padding: 2,
          lineHeight: 1,
          width: "fit-content",
          alignSelf: "flex-end",
          mx: 2,
          cursor: "pointer",
        })}
        tabIndex={0}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
        aria-describedby="info"
      >
        ？
      </div>
      <div
        id="info"
        className={cx(
          css({
            position: "absolute",
            bottom: -2,
            right: 12,
            width: "xl",
            color: "neutral.500",
            backgroundColor: "neutral.200",
            padding: 6,
            borderRadius: "xl",
            zIndex: 100,
            boxShadow: "lg",
            visibility: "visible",
            opacity: 1,
            transition: "0.25s",
          }),
          !(isFocus || isHover) && css({ visibility: "hidden", opacity: 0 }),
        )}
        onMouseOver={() => {
          (isHover || isFocus) && setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        role="tooltip"
      >
        <h3
          className={css({
            fontSize: "lg",
            fontWeight: "bold",
          })}
        >
          キーボードでリアクションをする
        </h3>
        <ul className={css({ display: "flex", justifyContent: "space-between", my: 6 })}>
          {reactions.map((reaction) => {
            return (
              <li className={css({ display: "flex", alignItems: "center" })}>
                <kbd className={kbdStyle({ size: "sm" })}>{reaction.keyboard}</kbd>
                <ReactionContent
                  className={css({ h: 8, mx: 2, objectFit: "contain" })}
                  reaction={reaction.reaction_key}
                />
              </li>
            );
          })}
        </ul>
        <ul className={css({ display: "flex", justifyContent: "space-evenly", my: 6 })}>
          <li className={controlItemStyle}>
            <kbd className={cx(kbdStyle({ size: "lg" }), css({ fontSize: "xs", mr: 4 }))}>
              <span className={css({ display: "flex", flexDir: "column", lineHeight: 0.9, marginInlineStart: "-4px" })}>
                <span>Back</span>
                <span>Space</span>
              </span>
            </kbd>
            <p className={labelStyle}>一文字消す</p>
          </li>
          <li className={controlItemStyle}>
            <kbd className={cx(css({ pos: "relative", height: 16 }))}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="38"
                viewBox="0 0 34 38"
                role="img"
                aria-label=""
                className={css({ height: "100%", width: "auto", marginTop: "1.5px" })}
              >
                <path
                  fill="#fff"
                  d="M3 20V5c0-1.6 1.3-2 2-2h22c1.6 0 2 1.3 2 2v31c0 1.6-1 2-1.5 2H13c-1.6 0-2-1.3-2-2V24c0-1.6-1.3-2-2-2H5c-1.6 0-2-1.3-2-2Z"
                />
                <path
                  stroke={token("colors.neutral.400")}
                  fill="none"
                  stroke-width="1.3"
                  d="M1 18V3c0-1.6 1.3-2 2-2h22c1.6 0 2 1.3 2 2v31c0 1.6-1 2-1.5 2H11c-1.6 0-2-1.3-2-2V22c0-1.6-1.3-2-2-2H3c-1.6 0-2-1.3-2-2Z"
                />
              </svg>
              <span className={css({ color: "neutral.400", pos: "absolute", top: 0, fontSize: "xs", mx: 1.5, my: 2 })}>
                Enter
              </span>
            </kbd>
            <p className={labelStyle}>リアクションを送信</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
