import { useState } from "react";

import { Reaction } from "./reaction.tsx";
import { ReactionInformation } from "./reactionInformation.tsx";

import TimeTable from "@/src/components/timetable.tsx";
import { css, cva, cx } from "@/styled-system/css";
import { visuallyHidden } from "@/styled-system/patterns";

const heading = cva({
  base: {
    flex: 1,
    py: 1,
    cursor: "pointer",
    textAlign: {
      base: "center",
      lg: "left",
    },
    rounded: "full",
  },
  variants: {
    status: {
      active: {
        base: {
          background: "white",
          color: "sohosai.orange",
        },
        lg: {
          background: "none",
          color: "white",
        },
      },
      default: {
        background: "none",
        color: "white",
      },
    },
  },
  defaultVariants: {
    status: "default",
  },
});

export const ReactionContainer = () => {
  const [isReaction, setIsReaction] = useState(true);
  return (
    <div>
      <div
        className={css({
          background: "white",
          rounded: "xl",
          boxShadow: "0px 4px 4px 0 rgba(0, 0, 0, 0.25)",
          overflow: "hidden",
        })}
      >
        <div
          className={css({
            background: "sohosai.orange",
            color: "white",
            fontWeight: "bold",
            borderTopRadius: "xl",
            padding: 3,
            display: "flex",
            justifyContent: { base: "space-around", lg: "start" },
            gap: 2,
          })}
        >
          <h2
            className={cx(heading({ status: isReaction ? "active" : "default" }))}
            onClick={() => setIsReaction(true)}
          >
            リアクション
          </h2>
          <h2
            className={cx(css({ hideFrom: "lg" }), heading({ status: !isReaction ? "active" : "default" }))}
            onClick={() => setIsReaction(false)}
          >
            タイムテーブル
          </h2>
        </div>
        <hr className={css({ marginBlockStart: 1, borderWidth: "1px", color: "sohosai.orange" })} />
        <div
          className={css({
            display: {
              base: isReaction ? "block" : "none",
              lg: "block",
            },
            lg: {
              maxH: "calc(100% - 2rem - 50px)",
              overflowY: "auto",
            },
          })}
        >
          <Reaction />
        </div>
        <div className={cx(css({ position: "relative", hideFrom: "lg" }), isReaction && visuallyHidden())}>
          <TimeTable />
        </div>
      </div>
      <div className={css({ display: "none", lg: { display: "block" } })}>
        <ReactionInformation />
      </div>
    </div>
  );
};
