import { z } from "zod";

import { decodeReaction, encodeReaction } from "./reaction";

import type { ReactionData, ReactionTypes } from "./reaction";

const Res = z.object({
  id: z.string(),
  content: z.string(),
  created_at: z.string(),
});

export const postReaction = async (reaction: Array<ReactionTypes>): Promise<ReactionData> => {
  const encodedReaction = reaction.map(encodeReaction).join(",");
  const res = await fetch(`${import.meta.env.VITE_ENDPOINT}/reaction`, {
    method: "POST",
    body: JSON.stringify({ content: encodedReaction }),
  });
  if (!res.ok) {
    throw new Error(await res.text());
  }
  const reactions = Res.parse(await res.json());
  return {
    id: reactions.id,
    content: reactions.content
      .split(",")
      .map(decodeReaction)
      .filter((v) => !!v),
    created_at: reactions.created_at,
  };
};
