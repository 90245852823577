import { ReactionContent } from "./reactionContent";

import type { ReactionTypes } from "./reaction";

import { css } from "@/styled-system/css";

type Props = {
  reaction: Array<ReactionTypes>;
};

export const ReactionItem = (props: Props) => {
  return (
    <ul
      className={css({
        display: "flex",
        flexWrap: "wrap",
        px: 0.5,
        py: 1,
        "&~&": {
          borderY: "1px dashed token(colors.gray.300)",
        },
      })}
    >
      {props.reaction.map((reaction, index) => (
        <ReactionContent key={index} className={css({ height: 10, objectFit: "contain" })} reaction={reaction} />
      ))}
    </ul>
  );
};
