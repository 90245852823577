import { useAtom } from "jotai";

import { Counter } from "./counter";
import { FuwaFuwaSopotan } from "./fuwafuwaSopotan";
import { reactionCountAtom, reactionsAtom } from "./reactions/reaction_atom";

import { sopotanGaudgeMax } from "@/src/consts";
import { css } from "@/styled-system/css";

export function SopotanGauge() {
  const reactions = useAtom(reactionsAtom)[0];
  const reactionCount = useAtom(reactionCountAtom)[0];
  return (
    <>
      <div className={css({ display: "flex", alignItems: "center", marginTop: { base: 10, lg: 0 } })}>
        <div
          className={css({
            borderRadius: "full",
            borderWidth: "4px",
            borderColor: "gray.300",
            background: "white",
            padding: 2,
            flexGrow: 1,
            width: "full",
            position: "relative",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          })}
        >
          {reactions.map((reaction) => (
            <FuwaFuwaSopotan
              key={reaction.id}
              reactionType={reaction.content[0]}
              postedBy={reaction.postBy}
              isFirst={reaction.isFirst}
            />
          ))}
          {Array.from({ length: 5 }).map(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            (_, index) => (
              <div
                key={index}
                className={css({
                  width: 2,
                  height: index % 2 ? 4 : 6,
                  position: "absolute",
                  background: "gray.300",
                  bottom: 0,
                  borderRadius: "9999px 9999px 0 0",
                  zIndex: 300,
                })}
                style={{
                  left: `${7 + index * 8}%`,
                }}
              />
            ),
          )}
          <div
            className={css({
              bgGradient:
                "linear-gradient(90deg, token(colors.sohosai.blue) 13%, #FFD467 58%, token(colors.sohosai.orange) 87%)",
              height: "32px",
              borderRadius: "full",
              width: "full",
              overflow: "hidden",
              zIndex: 100,
            })}
          >
            <div
              className={css({
                position: "absolute",
                bottom: "6px",
                right: "0",
                zIndex: "300",
                paddingX: "22px",
                fontFamily: "ZenDots",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "rgba(34, 31, 31, 0.50)",
              })}
            >
              <Counter count={reactionCount} width={16.4166} rightJustified />
            </div>
            <div
              style={{ transform: `translateX(${((reactionCount % sopotanGaudgeMax) / sopotanGaudgeMax) * 100}%)` }}
              className={css({
                transition: "transform 1s",
                transform: "translateX(0)",
                zIndex: 200,
                position: "relative",
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  animationName: "scrollUp",
                  animation: "linear",
                  animationDuration: "3s",
                  animationIterationCount: "infinite",
                })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="1332" height="98" fill="none">
                  <path
                    fill="#fff"
                    d="M1331.5 0H7.6834c-10.2444 10.8889-10.2444 21.7778 0 32.6667 10.2443 10.8889 10.2443 21.7778 0 32.6666C-2.561 76.2222-2.561 87.1111 7.6833 98H1331.5V0Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
