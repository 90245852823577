import { useEffect, useRef } from "react";

import * as Sentry from "@sentry/react";
import { Provider } from "jotai";
import { Toaster } from "react-hot-toast";

import Footer from "./components/footer";
import Header from "./components/header";
import Information from "./components/info.tsx";
import Player from "./components/player.tsx";
import PlayerDebugger from "./components/PlayerDebugger.tsx";
import { Polling } from "./components/polling.tsx";
import { ReactionContainer } from "./components/reactions/reactionContainer.tsx";
import { SopotanGauge } from "./components/sopotanGauge";
import Summary from "./components/summary.tsx";
import { TimetableContainer } from "./components/timetableContainer.tsx";

import { css } from "@/styled-system/css";

const uri = import.meta.env.VITE_ENDPOINT + "/ws";

function Page() {
  // https://qiita.com/_ytori/items/a92d69760e8e8a2047ac
  const socketRef = useRef<WebSocket>();

  const wsConnect = () => {
    const ws = new WebSocket(uri);
    socketRef.current = ws;

    ws.onclose = () => {
      wsConnect();
    };
  };

  useEffect(() => {
    wsConnect();
  }, [socketRef]);

  return (
    <Provider>
      <Header />
      <div className={css({ display: "flex", justifyContent: "center" })}>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            lg: { flexDirection: "row", justifyContent: "center" },
            width: "100vw",
            maxWidth: "8xl",
            position: "relative",
          })}
        >
          <div
            className={css({
              position: "sticky",
              top: 0,
              zIndex: 250,
              lg: {
                position: "static",
              },
              hideFrom: "lg",
            })}
          >
            <div className={css({ aspectRatio: "16 / 9" })}>
              <Player />
            </div>
            <Summary />
            {window.location.search === "?debug" && <PlayerDebugger />}
            <hr className={css({ color: "gray.400", lg: { marginBlock: 4, marginInline: 4 } })} />
          </div>
          <div
            className={css({
              width: "100%",
              lg: {
                padding: 3,
              },
            })}
          >
            <div
              className={css({
                position: "sticky",
                top: 0,
                zIndex: 1,
                lg: {
                  position: "static",
                },
                hideBelow: "lg",
              })}
            >
              <div className={css({ aspectRatio: "16 / 9" })}>
                <Player />
              </div>
              <Summary />
              {window.location.search === "?debug" && <PlayerDebugger />}
              <hr className={css({ color: "gray.400", lg: { marginBlock: 4, marginInline: 4 } })} />
            </div>
            <div>
              <Information />
              <TimetableContainer />
            </div>
            <Footer style="PC" />
          </div>
          <div
            className={css({
              width: "100%",
              py: "2",
              maxHeight: "calc(100dvh - {spacing.16})", // header.tsx と合わせる
              flexShrink: 0,
              lg: {
                width: "33.3vw",
                maxWidth: "md",
                maxHeight: "calc(100dvh - {spacing.20})", // header.tsx と合わせる
                display: "flex",
                flexDir: "column",
                gap: 8,
              },
              position: "sticky",
              top: 0,
              right: 0,
              zIndex: 100,
            })}
          >
            <Polling />
            <ReactionContainer />
            <SopotanGauge />
          </div>
        </div>
      </div>
      <Footer style="SP" />
      <Toaster />
    </Provider>
  );
}

const App = Sentry.withProfiler(Page);

export default App;
