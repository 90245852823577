import { z } from "zod";

import { decodeReaction } from "./reaction";

import type { ReactionData } from "./reaction";

type ReactionRes = {
  reactions: Array<ReactionData>;
  reaction_count: number;
  connection_count: number | null;
  live_url: string | null;
};

const Res = z.object({
  reactions: z.array(
    z.object({
      id: z.string(),
      content: z.string(),
      created_at: z.string(),
    }),
  ),
  reaction_count: z.number(),
  connection_count: z.number().nullable(),
  live_url: z.string().nullable(),
});

export const getReactions = async (cursor?: string): Promise<ReactionRes> => {
  const res = await fetch(`${import.meta.env.VITE_ENDPOINT}/reaction${cursor ? `?cursor=${cursor}` : ""}`);
  if (!res.ok) {
    throw new Error(await res.text());
  }
  const reactions = Res.parse(await res.json());
  return {
    reactions: reactions.reactions.map((reaction) => ({
      id: reaction.id,
      content: reaction.content
        .split(",")
        .map(decodeReaction)
        .filter((v) => !!v),
      created_at: reaction.created_at,
    })),
    reaction_count: reactions.reaction_count,
    connection_count: reactions.connection_count,
    live_url: reactions.live_url,
  };
};
